import { Box, Button, IconButton, TableCell, TableRow, Typography } from '@mui/material';
import React, { FC, memo, useCallback } from 'react';
import { Iconify } from 'src/UIKit/Iconify';
import { useUiContext } from 'src/UIProvider';
import { ContextMenu } from 'src/UIKit/ContextMenu';
import { INltdevskeleton } from 'src/modules/nltdevskeletons/entity/nltdevskeletons/INltdevskeleton';
import { styles } from './styles';
import { useNltdevskeletonsTableItem } from '../../../presenters/useNltdevskeletonsTableItem';
import { IOSSwitch } from 'src/UIKit/IOSSwitch';
import { notification } from 'antd';

interface IProps {
    item: INltdevskeleton;
};

export const NltdevskeletonsTableRow: FC<IProps> = memo(({ item }) => {
    const { t } = useUiContext();
    const { open, onOpenMenu, onCloseMenu, onDelete, onEdit, onActiveSwitch, informUsers, loading } = useNltdevskeletonsTableItem(item);

    return (
        <>
            <TableRow hover  >
                <TableCell align="center" width="10%">
                    {item.image ? <img src={item.image.path} alt="image" style={styles.image as any} /> : 'no image'}
                </TableCell>
                <TableCell component="th" scope="row" padding="none" align="center" width="35%">
                    <Typography variant="subtitle2" sx={styles.text}>
                        {item.name}
                    </Typography>
                </TableCell>
                <TableCell align='center' width="30%">
                    <Typography variant="subtitle2" sx={styles.category}>{item?.category?.path || '-'}</Typography>
                </TableCell>
                <TableCell align='center' width="5%">
                    {item.available && <Button variant='contained' onClick={informUsers} disabled={loading}>{t('notifications.inform')}</Button>}
                </TableCell>
                <TableCell align={'center'} width={140}>
                    <Box width={140}>
                        <IOSSwitch label={t('common.active')} secondLabel={t('common.noActive')} name="available" onChange={onActiveSwitch} checked={item.available} />
                    </Box>
                </TableCell>
                <TableCell align="right" width="5%">
                    <IconButton size="large" color="inherit" onClick={onOpenMenu}>
                        <Iconify icon={'eva:more-vertical-fill'} />
                    </IconButton>
                </TableCell>
            </TableRow>
            <ContextMenu open={open} onClose={onCloseMenu} onDelete={onDelete} onEdit={onEdit} />
        </>
    );
})
