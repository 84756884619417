import { TableCell, TableRow, Typography } from '@mui/material';
import React, { FC, memo } from 'react';
import { useSubscriptionsTableItem } from '../../../presenters/useSubscriptionsTableItem';
import { ISubscription } from '../../../entity/subscriptions/ISubscription';
import moment from 'moment';
import { TextInput } from 'src/UIKit/TextInput';
import {styles} from './styles';
import { useUiContext } from 'src/UIProvider';

interface IProps {
    item: ISubscription;
};

export const SubscriptionTableRow: FC<IProps> = memo(({ item }) => {
    const { t } = useUiContext();
    const { price, onChangePrice, handleBlur } = useSubscriptionsTableItem(item);
    const createdDate = moment(item.created_at).format('DD/MM/YYYY'); 

    const FREQUENCY = {
       DAY: t('subscriptions.day'), 
       WEEK: t('subscriptions.week'), 
       MONTH: t('subscriptions.month'), 
       YEAR: t('subscriptions.year'), 
    };

    return (
        <>
            <TableRow hover >
                <TableCell align='left' width='25%'>
                    <Typography variant="subtitle2" ml={3} sx={styles.text}>{item.name}</Typography>
                </TableCell>
                <TableCell align='center' width='15%'>
                    <Typography variant="subtitle2" noWrap>{FREQUENCY[item.frequency_unit]}</Typography>
                </TableCell>
                {/* <TableCell align={'center'} width='15%'>
                    <Typography variant="subtitle2" noWrap>{item.frequency_value}</Typography>
                </TableCell> */}
                {/* <TableCell align={'center'} width='15%'>
                    <Typography variant="subtitle2" noWrap>{createdDate}</Typography>
                </TableCell> */}
                <TableCell align='center' width='15%'>
                    <Typography variant="subtitle2" noWrap>{item.currency}</Typography>
                </TableCell>
                <TableCell align={'center'} width='10%'>
                    <TextInput name='price' onChange={onChangePrice} value={price} onBlur={handleBlur}/>
                </TableCell>
            </TableRow>
        </>
    );
});
