import React, { FC } from 'react';
import { Card, Table, TableBody, TableContainer } from '@mui/material';
import { useUiContext } from 'src/UIProvider';
import { observer } from 'mobx-react-lite';
import { TableHeader } from 'src/UIKit/TableHeader';
import { subscriptionsModel } from '../../../entity/subscriptions/SubscriptionsModel';
import { useSubscriptionsTable } from '../../../presenters/useSubscriptionsTable';
import { SubscriptionTableRow } from '../tableRow';

export const SubscriptionTable: FC = observer(() => {
    const { t } = useUiContext();
    useSubscriptionsTable();
 
    const TABLE_HEAD = [
        t('subscriptions.name'), 
        t('subscriptions.type'), 
        // t('subscriptions.duration'), 
        // t('subscriptions.created'), 
        t('subscriptions.currency'), 
        t('subscriptions.price')
    ];

    return (
        <Card>
            <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                    <TableHeader headLabels={TABLE_HEAD} />
                    <TableBody>
                        {subscriptionsModel.subscriptions?.map((item) => (<SubscriptionTableRow item={item} key={item.id} />))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Card>
    );
});
